import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { CONTACT } from "../../../../campaign-config"
import LeadGeneration from "../../LeadGeneration"

const ContactLeadGeneration = () => {
  const intl = useIntl()

  return (
    <LeadGeneration campaign={CONTACT} className="whatsapp-bg-color btn-block">
      <div
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            defaultMessage:
              "<b>Sign up now</b> and <b>test your first event for free</b>. You're just a few clicks away from increasing your ticket sales.",
          }),
        }}
      />
    </LeadGeneration>
  )
}

export default ContactLeadGeneration
