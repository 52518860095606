import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { Badge, Col, Container, Row } from "react-bootstrap"
import Image2 from "../../../assets/images/campaign-contact/undraw_filter_4kje.svg"
import Image3 from "../../../assets/images/campaign-contact/undraw_selecting_1lx3.svg"
import Image1 from "../../../assets/images/campaign-contact/undraw_to_the_moon_v1mv.svg"
import Image4 from "../../../assets/images/campaign-contact/undraw_yoga_248n.svg"
import "./scss/index.scss"

const Steps = () => {
  const intl = useIntl()

  return (
    <section className="py-8 steps bg-light">
      <Container>
        <Row className="mb-5 pb-5">
          <Col className="text-center">
            <h2>{intl.formatMessage({ defaultMessage: "How does it work?" })}</h2>
            <span
              className="lead"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage:
                    "A 100x tested and validated event page with smart shopping cart will help you to generate up to 5x more contacts, the first step to more ticket sales. But <b>that's just the beginning</b>. We help you to convert these contacts into buyers through automated reminders at the optimal time. Read on and learn more.",
                }),
              }}
            />
          </Col>
        </Row>

        <Row className="bg-white">
          <Col xs={12} className="mt-n5 text-center">
            <Badge className="my-3 whatsapp-bg-color">
              <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step-by-step" })}</p>
            </Badge>
          </Col>

          {/* Text left */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 2 }} className="text-center py-3">
              <img src={Image1} alt="..." className="steps__step-svg" data-aos="fade-left" />
            </Col>
            <Col xs={{ span: 8, order: 1 }} data-aos="fade-right">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 1" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage:
                    "Optimize every step for your lead generation and collect up to 5x more contacts for your event (GDPR compliant).",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage: "From landing page to shopping cart abandonment.",
                })}
              </p>
            </Col>
          </Row>

          {/* Text right */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 1 }} className="text-center py-3">
              <img src={Image2} alt="..." className="steps__step-svg" data-aos="fade-right" />
            </Col>
            <Col xs={{ span: 8, order: 2 }} data-aos="fade-left">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 2" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage: "Convert your generated contacts to ticket buyers.",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage:
                    "Best of all: It is completely automated. We send auto-reminders at the best times for more conversions.",
                })}
              </p>
            </Col>
          </Row>

          {/* Text left */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 2 }} className="text-center py-3">
              <img src={Image3} alt="..." className="steps__step-svg" data-aos="fade-left" />
            </Col>
            <Col xs={{ span: 8, order: 1 }} data-aos="fade-right">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 3" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage:
                    "Get insights into your contacts and understand who your top fans and visitors are.",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage:
                    "Start engagement campaigns and strengthen the relationship with your most important guests. Learn more about their interests and demographics.",
                })}
              </p>
            </Col>
          </Row>

          {/* Text right */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 1 }} className="text-center py-3">
              <img src={Image4} alt="..." className="steps__step-svg" data-aos="fade-right" />
            </Col>
            <Col xs={{ span: 8, order: 2 }} data-aos="fade-left">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 4" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage: "Seamlessly integrated into your frequently-used applications",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage:
                    "Export your leads to your frequently used applications with just one click.",
                })}
              </p>
            </Col>
          </Row>
        </Row>
      </Container>
    </section>
  )
}

export default Steps
