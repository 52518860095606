import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import PreviewImage from "../../../assets/images/campaign-contact/preview.jpg"
import MacBookSVG from "../../../assets/images/devices/macbook.svg"

const Preview = () => {
  return (
    <section className="pt-8">
      <Container>
        <Row>
          <Col>
            {/* Macbook + iPhone */}
            <div className="device-combo device-combo-macbook-iphonex mb-6 mb-md-0">
              {/* Macbook */}
              <div className="device device-macbook">
                <img src={PreviewImage} className="device-screen" alt="..." />
                <img src={MacBookSVG} className="img-fluid" alt="..." />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Preview
