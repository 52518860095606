import { IntlContextConsumer, useIntl } from "gatsby-plugin-intl"
import React from "react"
import { Container, Row } from "react-bootstrap"
import OverviewDE from "../../../assets/images/campaign-contact/overview-de.jpg"
import OverviewEN from "../../../assets/images/campaign-contact/overview-en.jpg"

const Hero = () => {
  const intl = useIntl()

  return (
    <section className="pt-6 pt-md-8">
      <Container>
        <Row className="align-items-center justify-content-center">
          <div className="col-10 col-sm-8 col-md-4 order-md-2">
            <IntlContextConsumer>
              {({ language: currentLocale }) => (
                <img
                  className="img-fluid"
                  src={currentLocale === "de" ? OverviewDE : OverviewEN}
                  alt=""
                />
              )}
            </IntlContextConsumer>
          </div>
          <div className="col-12 col-md-8 col-lg-5" data-aos="fade-right">
            {/* Heading */}
            <h1
              className="font-weight-bold"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage:
                    'The new <span class="text-whatsapp">CRM for event organizers</span> that you always missed.',
                }),
              }}
            />

            {/* Text */}
            <p className="font-size-lg text-muted mb-6">
              {intl.formatMessage({
                defaultMessage:
                  "Increase ticket sales. Save time. Automatically collect up to 5x more contacts.",
              })}
            </p>
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default Hero
