import React from "react"
import ContactLeadGeneration from "../components/campaign-contacts/ContactLeadGeneration"
import Hero from "../components/campaign-contacts/Hero"
import Preview from "../components/campaign-contacts/Preview"
import References from "../components/campaign-contacts/References"
import Steps from "../components/campaign-contacts/Steps"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Lead = () => (
  <Layout noFooter>
    <SEO title="5x mehr Leads" />
    <Hero />
    <ContactLeadGeneration />
    <Preview />
    <Steps />
    <References />
    <ContactLeadGeneration />
    <Footer disableCallToAction />
  </Layout>
)
export default Lead
