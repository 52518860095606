import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Organizer1 from "../../../assets/images/logos/organizer-1.png"
import Organizer2 from "../../../assets/images/logos/organizer-2.png"
import Organizer3 from "../../../assets/images/logos/organizer-3.png"
import Organizer4 from "../../../assets/images/logos/organizer-4.png"
import Organizer5 from "../../../assets/images/logos/organizer-5.png"

const organizerLogos = [Organizer1, Organizer2, Organizer3, Organizer4, Organizer5]

const References = () => {
  return (
    <section className="pt-5 pb-4 py-md-6 py-md-8">
      <Container>
        <Row className="align-items-center justify-content-center">
          {organizerLogos.map(organizer => (
            <Col key={organizer} xs="6" md="2" className="text-center mb-4 mb-md-0">
              <img className="img-fluid" src={organizer} alt="Organizer Logo" />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default References
